<template>
  <div class="select-pay-wrap">
    <van-nav-bar :border="false" left-text="" @click-left="back" left-arrow>
      <div slot="left">
        <img src="@/assets/images/system/left-arrow.svg"/>
      </div>
      <div slot="title" class="ub ub-cen">
        Selection Payment Method
      </div>
    </van-nav-bar>
    <div class="select-pay-main">
      <div class="amount-box text-c">
        <div class="font12 main-text-2">You will get</div>
        <div>
          <span class="font24">{{amount}}</span>
          <span class="font12 main-text-2">USDT</span>
        </div>
      </div>
      <div>
        <div class="ub ub-btw font12 main-text-2">
          <div>
            Pay with
          </div>
          <div>
            <span>You will pay</span>
            <span class="margin-left8">30S</span>
          </div>
        </div>
        <div class="pay-method-card  margin-top16" :class="{
          'pay-method-card-selected': item.key === selectValue
        }" v-for="(item, index) in methodList" @click="selectTarget(item)" :key="index">
          <div>
            <p>Card</p>
            <div>
              <img src="@/assets/images/walltes/VISA.svg" alt="">
            </div>
          </div>
          <div class="font16 font-weight500">
            {{amount}} USD
          </div>
          <div v-if="item.key === selectValue" class="selected-pop">
            <img src="@/assets/images/walltes/pay-method-c.svg" alt="">
          </div>
        </div>
      </div>
      <div class="footer-box ub ub-btw">
        <div class="left-box">
          <p class="font10 main-text-2">You will pay</p>
          <p class="font14 font-weight500">{{amount}} USD</p>
        </div>
        <div class="buy-btn-box ub ub-cen">
          <div class="primary-btn-color ub ub-cen font16">
            Buy USDT
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectionPaymentMethod',
  props: {
  },
  data () {
    return {
      type1: 'USD',
      type2: 'USDT',
      selectValue: 1,
      showKeyBoard: true,
      methodList: [
        {
          key: 1
        },
        {
          key: 2
        }
      ],
      amount: ''
    }
  },
  mounted () {
    this.amount = this.$route.query.amount
  },
  methods: {
    selectTarget (item) {
      console.log(item, '-------')
      this.selectValue = item.key
    },
    goHistory () {
      console.log('goHistory')
    },
    back () {
      this.$router.back()
    }
  }
}
</script>
<style lang="less">
.select-pay-main{
  padding: 30px;
  box-sizing: border-box;
  .footer-box{
    box-sizing: border-box;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    .left-box{
      padding: 30px;
      box-sizing: border-box;
    }
    .buy-btn-box{
      height: 96px;
      padding: 30px;
      box-sizing: border-box;
      width: 40%;
      .primary-btn-color{
        width: 100%;
        height: 96px;
        border-radius: 4px;
      }
    }
  }
  .amount-box{
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .pay-method-card{
    display: flex;
    padding: 24px 32px;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 8px;
    border: 0.5px solid #ECEAEF;
  }
  .pay-method-card-selected{
    border-radius: 16px;
    position: relative;
    border: 1px solid #5B21CB;
    .selected-pop{
      position: absolute;
      bottom: -11px;
      right: 0;
    }
  }
}
</style>
